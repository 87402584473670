import React from 'react'
import SEO from '../../components/seo'
import SubPageLayoutNoHero from '../../layouts/subPageLayoutNoHero'
import styled from 'styled-components'
import { Section } from '../../components/styled/section'
import SubPageImgHero from '../../components/subPageImgHero'
import ChooseRegion from '../../components/small_web_comp/ChooseRegion'
const PageSection = styled(Section)`
  display: flex;
  align-items: center;
  margin: 15px auto;
`
const Text = styled.div`
  flex: 1;
  margin-top: -3rem;
  h4 {
    border-bottom: 2px solid #dddddd;
  }
`

const Oljeskift = () => {
  const title = 'Oljeskift på motor og girkasse'
  const description1 = ''
  const Img = require('../../images/oljeskift_small.png')

  return (
    <div>
      <SubPageLayoutNoHero title={title} description1={description1}>
        <SubPageImgHero title={title} description1={description1} Img={Img}></SubPageImgHero>
        <SEO title={title} />
        <PageSection>
          <Text>
            <h4>Oljeskift av motorolje</h4>
            <p>
              Olje og filterskift blir gjort på hver service, er det ikke på tide med en service,
              anbefaler vi at det skiftes olje og filter en gang i året.
            </p>
            <p>
              Vi sørger for at riktig olje etter bilprodusentens spesifikasjoner fylles på din bil.
              Det er meget viktig at riktig olje fylles på da oljen inneholder additiver som er
              tilpasset din motor og turbo.
            </p>
            <h4>Oljeskift på girkasse </h4>
            <p>
              BilXtra verkstedene utfører oljeskift på automatkasse og oljeskift på manuell
              girkasse. Vi sørger for at riktig olje kvalitet i forhold til bilprodusentenes
              spesifikasjoner.
            </p>
            <ChooseRegion />
          </Text>
        </PageSection>
      </SubPageLayoutNoHero>
    </div>
  )
}

export default Oljeskift
